.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  margin: 16px auto;
}
.addBtn {
  position: fixed;
  right: 44px;
  bottom: 44px;
}
.whiteBtn {
  background-color: white!important;
  box-shadow: 0 0 10px white!important;
}
