%topnav {
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  transition: height 0.5s;
  transition-timing-function: ease;
}
.topnav {
  @extend %topnav;
  background-color: black;
  height: 56px;
  border-bottom: 1px solid #FFA9F2;
  @media print {
    display: none;
  }
}
.topnav_full {
  @extend %topnav;
  background-color: black;
  height: 100vh;
  @media print {
    display: none;
  }
}
.link_group {
  width: 100%;
  height: 56px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.link_group_mobile {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  padding: 20px;
}
.hidden {
  display: none;
}
.logo {
  margin-right: 16px;
}
.icon_group {
  display: grid;
  grid-template-columns: auto auto;
  margin-left: 16px;
}
.profile_text {
  color: white;
  font-family: 'New_Zelek';
  font-size: 20px;
}
.white_icon {
  fill: white;
}
