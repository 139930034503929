.container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 56px);
}
.title {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  p {
    margin: 0;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: calc(1em + 0.5vw);
  }
}
.overflowBox {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 96px);
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.box {
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 0 5px white, inset 0 0 5px white;
  font-size: 1em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 2px;
  left: 4px;
  cursor: pointer;
}
.footer {
  width: 100%;
  height: 48px;
  display: flex;
  flex-flow: row-reverse nowrap;
}
.suggestion {
  box-sizing: border-box;
  width: 100%;
  height: 70vh;
  display: grid;
  padding-top: 4px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(12, 1fr);
  gap: 4px;
}
.suggestBox {
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testBox {
  background-color: #f8bbd0;
}
