.container {
  width: 100%;
  padding-top: 48px;
}
.banner {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 56px;
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  background-color: black;
  z-index: 2;
}
.planlabel {
  position: relative;
  cursor: pointer;
  svg {
    height: 48px;
  }
  &::before {
    content: 'Plan';
    position: absolute;
    left: 70px;
    top: 12px;
    color: #f6ff00;
    text-shadow: 0 0 10px #f6ff00;
    font-size: 21px;
    font-family: 'Radio Stars';
  }
}
