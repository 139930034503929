.back_btn {
  position: fixed !important;
  top: 56px;
  left: 0;
  @media print {
    display: none !important;
  }
}
.changeview_btn {
  position: fixed !important;
  top: 56px;
  right: 0;
  @media print {
    display: none !important;
  }
}
.center_grid {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
}
.pink {
  color: #e91e63;
}
.video_viewer {
  width: 100%;
  max-width: 1000px;
  height: auto;
}
