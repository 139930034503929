.container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto 40px auto;
  padding: 0 24px;
}
.name {
  margin: 0 0 0 3vw;
  color: #f6ff00;
  font-family: 'PS LCD Matrix II';
  font-size: calc(15px + 0.5vw);
}
.box {
  margin-top: 12px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0 5vw;
}
@mixin text($color, $shadow) {
  margin: 12px 0;
  font-family: 'Pirulen';
  font-size: calc(15px + 0.5vw);
  color: $color;
  text-shadow: 0 0 5px $shadow;
}
.math {
  @include text(#ff8545, #ffd1a3);
}
.phy {
  @include text(#ce45ff, #e89aff);
  justify-self: center;
}
.chem {
  @include text(#00baff, #96fbff);
  justify-self: end;
}
