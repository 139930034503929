.container {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
}
.title {
  margin: 0;
  font-size: 30px;
  font-weight: 800;
}
.title_container {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: 4px;
}
.header_container {
  margin: 20px 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.header {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}
.header_btn {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: #40c4ff;
  }
}
.file_list {
  box-sizing: border-box;
  padding: 12px 0;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-column-gap: 12px;
}
.file_title {
  margin: 0;
}
.see_sheet {
  &:hover {
    color: #69f0ae;
    cursor: pointer;
  }
}
.disabled {
  color: #e0e0e0;
}
.upload {
  &:hover {
    color: #03a9f4;
    cursor: pointer;
  }
}
.trash {
  &:hover {
    color: red;
    cursor: pointer;
  }
}
.fab {
  position: fixed !important;
  bottom: 24px;
  right: 24px;
}
