.container {
  width: 100%;
  height: calc(100vh - 56px);
  display: grid;
  grid-template-rows: 1fr auto;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainGrid {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: center;
  grid-column-gap: 52px;
}
.inputBox {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  justify-items: center;
  grid-row-gap: 44px;
}
.label {
  margin: 0;
  font-family: 'Digital-7 Italic';
  font-size: 60px;
  color: white;
  -webkit-text-stroke: 2px #ffb000;
  text-shadow: 0 0 13px #ffb000;
  letter-spacing: 8px;
}
.input {
  background-color: black;
  border: none;
  width: 4.4em;
  font-family: 'Digital-7';
  font-size: 200px;
  color: white;
  letter-spacing: 0.25em;
  caret-color: transparent;
  &:focus {
    border: none;
    outline: none;
    -webkit-text-stroke: 0.8px #00f5ff;
    text-shadow: 0 0 15px #00f5ff;
  }
}
.footer {
  width: 95%;
  margin: 0 auto;
}
