.uploadSpace {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.cloudIcon {
  width: 50% !important;
  height: 50% !important;
  color: #eeeeee;
  cursor: pointer;
  &:hover {
    color: #b1b0b0;
  }
}
