.container {
  display: flex;
  flex-flow: row wrap;
}

.qr {
  display: flex;
  flex-flow: column nowrap;
  margin: 1em;
  align-items: center;
}

.qr_container {
  margin-bottom: 1em;
}

.label {
  font-weight: 500;
  font-size: 18px;
}
