.container {
  width: 100%;
  height: calc(100vh - 56px);
  display: grid;
  grid-template-rows: auto 1fr;
}
.header {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  margin: 0;
  font-family: 'NeonTube2';
  font-size: 65px;
  color: white;
  -webkit-text-stroke: 0.5px #ceff00;
  text-shadow: 0 0 15px #ceff00;
}
.backBtn {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 20px;
}
.content {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr 2fr;
}
.subjCol {
  display: flex;
  box-sizing: border-box;
  flex-flow: column nowrap;
  padding: 0 8px;
}
@mixin box {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'NeonTube2';
  font-size: 37px;
  color: #757575;
  border: 5px solid #757575;
  box-shadow: 0 0 10px #757575, inset 0 0 10px #757575;
}
.subjBox {
  @include box();
  margin: 8px 0;
  padding: 20px;
  flex: 1;
}
@mixin subjTxt($color, $shadow) {
  color: $color;
  -webkit-text-stroke: 0.5px $color;
  text-shadow: 0 0 10px $shadow;
  border: 5px solid $color;
  box-shadow: 0 0 10px $shadow, inset 0 0 10px $shadow;
}
.MN {
  @include subjTxt(#00ff45, #00ff45);
}
.MK {
  @include subjTxt(#ffe1a7, #ffdaaf);
}
.MJ {
  @include subjTxt(#ffb100, #ffe09b);
}
.MH {
  @include subjTxt(#ff8545, #ffd1a3);
}
.MU {
  @include subjTxt(#ff6200, #ff9d60);
}
.PJ {
  @include subjTxt(#ce45ff, #e89aff);
}
.PH {
  @include subjTxt(#ff00ff, #ff5fff);
}
.PU {
  @include subjTxt(#6c00ff, #b162ff);
}
.CJ {
  @include subjTxt(#00ffe2, #89fff2);
}
.CH {
  @include subjTxt(#00baff, #96fbff);
}
.listCol {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 16px;
  padding: 8px;
}
.listBox {
  @include box();
}
.levelCol {
  display: grid;
  box-sizing: border-box;
  grid-template-rows: 1fr auto;
  padding: 8px;
}
.levelRow {
  display: flex;
  box-sizing: border-box;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 24px;
}
.levelBox {
  @include box();
  flex: 1;
  width: 100%;
  margin-bottom: 16px;
}
.activeLevel {
  @include subjTxt(#e6ff29, #7effdc);
}
.printRow {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 16px;
}
.printBox {
  @include box();
  padding: 12px;
}
.activePrint {
  @include subjTxt(#ff00e2, #ff00e2);
}
.numCol {
  display: grid;
  box-sizing: border-box;
  grid-template-rows: 1fr 64px auto;
  padding: 8px;
  grid-row-gap: 16px;
}
.revRow {
  display: flex;
  flex-flow: row nowrap;
}
.revBox {
  @include box();
  flex: 1;
  margin-right: 8px;
}
.numRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 16px;
}
.numBox {
  @include box();
  @include subjTxt(#7effa6, #7effa6);
}
.delBox {
  @include box();
  @include subjTxt(#ff0000, #ff8383);
}
.btnRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.active {
  @include subjTxt(white, white);
}
.overlayBG {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.dialog {
  box-sizing: border-box;
  padding: 20px;
  background-color: black;
  border: 2px solid #ff80ab;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff80ab, inset 0 0 10px #ff80ab;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.loadingText {
  margin: 0;
  font-size: 40px;
  color: white;
  -webkit-text-stroke: 0.5px #ff80ab;
  text-shadow: 0 0 10px #ff80ab;
}
