.container {
  min-width: 100%;
  height: calc(100vh - 56px);
  box-sizing: border-box;
  overflow: scroll;
  display: flex;
  flex-flow: row nowrap;
}
.fab {
  position: fixed !important;
  bottom: 24px;
  right: 24px;
}
@mixin neon($border, $shadow, $hover) {
  border: 5px solid $border;
  box-shadow: 0 0 10px $shadow, inset 0 0 10px $shadow;
  &:hover {
    background-color: $hover;
  }
}
.main_grid {
  box-sizing: border-box;
  margin: 16px;
  width: 160px;
  border-radius: 15px;
  display: grid;
  grid-template-rows: 1fr 4fr;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  border: 5px solid white;
  box-shadow: 0 0 10px white, inset 0 0 10px white;
  &:hover {
    background-color: #FFFFFF33;
  }
  &.mn {
    @include neon(#00ff45, #00ff45, #00ff4533);
  }
  &.mk {
    @include neon(#ffe1a7, #ffdaaf, #ffe1a733);
  }
  &.mj {
    @include neon(#ffb100, #ffe09b, #ffb10033);
  }
  &.mh {
    @include neon(#ff8545, #ffd1a3, #ff854533);
  }
  &.mu {
    @include neon(#ff6200, #ff9d60, #ff620033);
  }
  &.pj {
    @include neon(#ce45ff, #e89aff, #ce45ff33);
  }
  &.ph {
    @include neon(#ff00ff, #ff5fff, #ff00ff33);
  }
  &.pu {
    @include neon(#6c00ff, #b162ff, #6c00ff33);
  }
  &.cj {
    @include neon(#00ffe2, #89fff2, #00ffe233);
  }
  &.ch {
    @include neon(#00baff, #96fbff, #00baff33);
  }
}
@mixin neonText($border, $shadow) {
  color: $border;
  text-shadow: 0 0 10px $shadow;
}
.title {
  margin: 0;
  font-family: 'NeonTube2';
  font-size: 80px;
  color: white;
  text-shadow: 0 0 10px white;
  &.mn {
    @include neonText(#00ff45, #00ff45);
  }
  &.mk {
    @include neonText(#ffe1a7, #ffdaaf);
  }
  &.mj {
    @include neonText(#ffb100, #ffe09b);
  }
  &.mh {
    @include neonText(#ff8545, #ffd1a3);
  }
  &.mu {
    @include neonText(#ff6200, #ff9d60);
  }
  &.pj {
    @include neonText(#ce45ff, #e89aff);
  }
  &.ph {
    @include neonText(#ff00ff, #ff5fff);
  }
  &.pu {
    @include neonText(#6c00ff, #b162ff);
  }
  &.cj {
    @include neonText(#00ffe2, #89fff2);
  }
  &.ch {
    @include neonText(#00baff, #96fbff);
  }
}
.grid {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  grid-row-gap: 20px;
}
.subgrid {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
@mixin subtitle($color) {
  margin: 0 0 8px 0;
  font-family: 'Radio Stars';
  font-size: 18px;
  color: $color;
  text-shadow: 0 0 1px white;
}
.level {
  @include subtitle(#a3a3a3);
}
.approved {
  @include subtitle(#00ff45);
}
.submitted {
  @include subtitle(#00baff);
}
.inprogress {
  @include subtitle(#ffc033);
}
.rejected {
  @include subtitle(#ff0000);
}
.text {
  margin: 0;
  font-family: 'Radio Stars';
  font-size: 30px;
  color: white;
  text-shadow: 0 0 1px white;
}
