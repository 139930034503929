.danger {
    color: #FF1744!important;
    &:hover{
        background-color: rgba(255,0,0,0.08)!important;
    }
}

.form {
    width: 100%;
}

.input {
    margin-bottom: 12px!important;
}
