@font-face {
  font-family: 'New_Zelek';
  src: url('./assets//fonts/new_zelek.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Hussar';
  src: url('./assets//fonts/HussarBoldOutline.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'NeonTube2';
  src: url('./assets//fonts/NeonTubes2.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Radio Stars';
  src: url('./assets//fonts/radioStars.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Digital-7';
  src: url('./assets//fonts/digital-7.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Digital-7 Italic';
  src: url('./assets//fonts/digital-7 (italic).ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'PS LCD Matrix II';
  src: url('./assets//fonts/PSLCDMatrixII.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Pirulen';
  src: url('./assets//fonts/pirulen_[allfont.net].ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
