.container {
  padding: 4px;
}
.box {
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 0 5px white, inset 0 0 5px white;
  font-size: 1em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  width: 12em;
  background-color: black;
}
.hidden {
  display: none;
}
.over {
  padding-left: 4em;
}
.test {
  border: 1px solid #f48fb1;
  box-shadow: 0 0 5px #f48fb1, inset 0 0 5px #f48fb1;
  color: #f48fb1;
}
