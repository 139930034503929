.background {
    width: 100vw;
    height: 100vh;
    background-color: black;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}

.title {
    margin: 0;
    color: #F8BBD0;
    font-weight: 800;
    font-size: 20vw;
    border: 3px solid #F8BBD0;
    box-sizing: border-box;
    margin-bottom: 12px;
    cursor: pointer;
}

.img {
    max-width: 90%;
    height: auto;
    cursor: pointer;
}