form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 3%;
}

.wrapper {
  margin: 1.5em 0;
  text-align: right;
}

.preview {
  position: relative;
}
