.container {
  width: 100%;
  height: calc(100vh - 56px);
  display: grid;
  grid-template-rows: auto 1fr auto auto;
}
.header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.studentName {
  margin: 0;
  font-family: 'PS LCD Matrix II';
  font-size: 35px;
  color: white;
}
.content {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 0 1.2%;
  grid-column-gap: 1.2%;
}
.iconBox {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}
.seperator {
  box-sizing: border-box;
  position: relative;
}
.seperatorLabel {
  position: absolute;
  top: 15%;
  left: 1.2%;
  margin: 0;
  font-family: 'New_Zelek';
  font-size: 2.2vw;
  color: #ffb000;
}
.footer {
  width: 100%;
  height: 10vh;
  box-sizing: border-box;
  padding: 0 1.2% 1.2% 1.2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.2%;
}
.historyBox {
  box-sizing: border-box;
  border: 2px solid #ffa9f2;
  border-radius: 10px;
  box-shadow: 0 0 6px #e89aff, inset 0 0 6px #e89aff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pirulen';
  font-size: 30px;
  color: #a8a8a8;
}
.fullPortGrid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 44px;
}
.portCol {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.portLabel {
  margin: 0;
  font-family: 'Radio Stars';
  font-size: 30px;
  color: #418d81;
  text-shadow: 0 0 15px #569a8f;
}
.portBox {
  width: 100%;
  margin-top: 16px;
  padding: 44px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 5px solid #ce45ff;
  box-shadow: 0 0 20px #e89aff, inset 0 0 20px #e89aff;
  font-size: 37px;
  font-family: 'Pirulen';
  color: #4d4d4d;
  cursor: pointer;
}
.active {
  color: white;
}
.overlayBG {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.dialog {
  box-sizing: border-box;
  padding: 20px;
  background-color: black;
  border: 2px solid #ff80ab;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff80ab, inset 0 0 10px #ff80ab;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.loadingText {
  margin: 0;
  font-size: 40px;
  color: white;
  -webkit-text-stroke: 0.5px #ff80ab;
  text-shadow: 0 0 10px #ff80ab;
}
