.topnav {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: center;
}

.sidenav {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  max-width: 900px;
  box-sizing: border-box;
  padding: 2em;
  margin: auto;
  background-color: white;
}
