.pdf_container {
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  @media screen {
    display: grid;
    justify-items: center;
    grid-row-gap: 8px;
    padding-bottom: 8px;
  }
}

.pdf_container_full {
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  @media screen {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-row: 8px;
    padding-bottom: 8px;
  }
}

.blank {
  box-sizing: border-box;
  width: 100vw;
  max-width: 1000px;
  padding-top: 141.3%;
  background-color: white;
  @media print {
    page-break-after: always;
    page-break-inside: avoid;
  }
}
