.summaryBox {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 8px;
}
.titleBox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}
.indicatorGrid {
  display: flex;
  width: 100%;
  height: 16px;
  padding-top: 16px;
  flex-flow: row wrap;
}
.indicatorBox {
  width: 2.6vw;
  max-width: 24px;
  margin: 0 2px;
  background-color: #bbdefb;
  &.Submitted {
    background-color: #ffc107;
  }
  &.PreChecked {
    background-color: #b9f6ca;
  }
  &.PostChecked {
    background-color: #69f0ae;
  }
  &.Approved {
    background-color: #00e676;
  }
  &.Rejected {
    background-color: red;
  }
}
.indicatorTestBox {
  margin: 0 2px;
  position: relative;
  div {
    position: absolute;
    width: 16px;
    height: 16px;
    left: -8px;
    top: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #bbdefb;
    &.Submitted {
      color: #ffc107;
    }
    &.PreChecked {
      color: #b9f6ca;
    }
    &.PostChecked {
      color: #69f0ae;
    }
    &.Approved {
      color: #00e676;
    }
    &.Rejected {
      color: red;
    }
  }
}
.spliter {
  margin: 0 4px;
  height: 100%;
  border: 1px solid #bdbdbd;
}
.sheetCode {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
}
.pointer {
  cursor: pointer;
}
.sheetContainer {
  width: 100%;
  position: relative;
}
.fullLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sheet {
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  border-top: 1px solid #dddddd;
  &:hover {
    background-color: #eeeeee;
  }
}
.sheetTest {
  @extend .sheet;
  background-color: #ffcec4;
  &:hover {
    background-color: #fcaa9a;
    color: #f0f0f0;
  }
}
.sheetTitle {
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  grid-column-gap: 12px;
  margin-bottom: 10px;
  align-items: center;
}
.sheetDescription {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.editBtn {
  &:hover {
    color: #40c4ff;
  }
}
.trashBtn {
  &:hover {
    color: red;
  }
}
.indicator {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #bbdefb;
  &.Submitted {
    background-color: #ffc107;
  }
  &.PreChecked {
    background-color: #b9f6ca;
  }
  &.PostChecked {
    background-color: #69f0ae;
  }
  &.Approved {
    background-color: #00e676;
  }
  &.Rejected {
    background-color: red;
  }
}
