.box {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 0 0 10px white, inset 0 0 10px white;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.text {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  font-size: calc(1em + 0.5vw);
  color: white;
  &:hover {
    cursor: pointer;
    text-shadow: 0 0 10px white;
  }
}
.deleteBtn {
  color: white;
  cursor: pointer;
}
