.link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-decoration: none;
  padding: 0 24px;
  p {
    font-family: 'Hussar';
    font-size: 20px;
    -webkit-text-stroke: 0.3px #ffaaf4;
    text-shadow: 0 0 6px #ffaaf4;
    color: white;
  }
  &:hover {
    background: rgb(255, 176, 248);
    background: radial-gradient(
      ellipse,
      rgba(255, 176, 248, 0.6) 0%,
      rgba(0, 0, 0, 1) 60%
    );
  }
}
.active {
  background: rgb(255, 176, 248);
  background: radial-gradient(
    ellipse,
    rgba(255, 176, 248, 0.6) 0%,
    rgba(0, 0, 0, 1) 60%
  );
}
.seperator {
  border: 0.5px solid #ffa9f2;
  height: 64%;
  box-shadow: 0 0 3px #ffa9f2;
}
