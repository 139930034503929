.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 12px auto;
  padding-top: 104px;
}

.topnav {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  z-index: 2;
}

.subtopnav {
  position: fixed;
  top: 104px;
  left: 0;
  width: 100%;
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  z-index: 2;
}

.fab {
  position: fixed !important;
  bottom: 24px;
  right: 24px;
}

.navlist {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  background-color: white;
  &.mn {
    background-color: #55ff82;
    &:hover {
      background-color: #00ff45;
    }
  }
  &.mk {
    background-color: #fdebb4;
    &:hover {
      background-color: #ffe082;
    }
  }
  &.mj {
    background-color: #ffe082;
    &:hover {
      background-color: #ffca28;
    }
  }
  &.mh {
    background-color: #ffca28;
    &:hover {
      background-color: #ffb300;
    }
  }
  &.mu {
    background-color: #ff8335;
    &:hover {
      background-color: #ff6200;
    }
  }
  &.pj {
    background-color: #d1c4e9;
    &:hover {
      background-color: #b39ddb;
    }
  }
  &.ph {
    background-color: #fa67fa;
    &:hover {
      background-color: #ff00ff;
    }
  }
  &.pu {
    background-color: #b39ddb;
    &:hover {
      background-color: #9575cd;
    }
  }
  &.cj {
    background-color: #bbdefb;
    &:hover {
      background-color: #64b5f6;
    }
  }
  &.ch {
    background-color: #64b5f6;
    &:hover {
      background-color: #2196f3;
    }
  }
}

.subnavlist {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  background-color: lightgray;
  &.active {
    background-color: white;
  }
}
