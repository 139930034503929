.form {
  width: 100%;
}

.input {
  margin-bottom: 12px !important;
}

.danger {
  color: #ff1744 !important;
  &:hover {
    background-color: rgba(255, 0, 0, 0.08) !important;
  }
}
